@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

body, html, #root {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: 'Inter', sans-serif;
}

@media (min-width: 600px) {
  body, html, #root {
    overflow-y: hidden;
    overflow-x: auto;
  }
}

/* Scrollbar styling */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: #f1f5f9;
}

::-webkit-scrollbar-thumb {
  background: #cbd5e1;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: #94a3b8;
}

/*
.custom-button {
  display: inline-flex;
  align-items: center;
  gap: 12px; 
  font-weight: 500;
  text-transform: none;
  border-radius: 4px;
  padding: 8px 16px;
  font-size: 0.875rem;
  transition: background-color 0.3s ease, color 0.3s ease;
}
.custom-button--primary {
  background-color: #47a8b5;
  color: #ffffff;
  border: none;
}
.custom-button--primary svg {
  color: #FFFFFF; 
}

.custom-button--primary:hover {
  background-color: #53c3d2;
}

.custom-button--secondary {
  background-color: #f8f9fa;
  color: #495057;
  border: 1px solid #e9ecef;
}

.custom-button--secondary:hover {
  background-color: #e9ecef;
}

@media (max-width: 600px) {
  .custom-button {
    width: 100%;
    justify-content: center;
    gap: 12px; 
  }
}
*/